import React, { FunctionComponent, useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FinancialPageQuery } from "../../../graphql/types"
import { PageProps } from "../../types/page"
import { Markdown } from "@components/markdown/markdown"
import { Page } from "@components/page/page"
import { PageBreadcrumbs } from "@core/breadcrumbs"
import { defaultMetadata } from "@core/constants"
import { PageHeader } from "@features/about/components/page-header/page-header"
import styles from "@features/about/components/financial-information-page/financial-information-page.module.scss"

enum FinancialYears {
  CurrentYear = "2023",
  PreviousYear = "2022"
}

export const FinancialInformationTemplate: FunctionComponent<
  PageProps<FinancialPageQuery>
> = ({ data, ...props }) => {
  const [selectedYear, setSelectedYear] = useState<FinancialYears>(
    data?.contentfulAboutFinancials?.currentYear
  )

  const expensesImage =
    selectedYear === FinancialYears["CurrentYear"]
      ? data?.contentfulAboutFinancials?.currentYearExpenses?.gatsbyImageData
      : data?.contentfulAboutFinancials?.previousYearExpenses?.gatsbyImageData
  const assetsImage =
    selectedYear === FinancialYears["CurrentYear"]
      ? data?.contentfulAboutFinancials?.currentYearAssets?.gatsbyImageData
      : data?.contentfulAboutFinancials?.previousYearAssets?.gatsbyImageData
  const revenueImage =
    selectedYear === FinancialYears["CurrentYear"]
      ? data?.contentfulAboutFinancials?.currentYearRevenue?.gatsbyImageData
      : data?.contentfulAboutFinancials?.previousYearRevenue?.gatsbyImageData

  return (
    <Page
      {...props}
      metadata={{
        ...defaultMetadata,
        title:
          data?.contentfulAboutFinancials?.pageTitle || defaultMetadata.title
      }}
      pageOptions={{ breadcrumbs: PageBreadcrumbs.FinancialInformation }}
    >
      <div className={styles.financialInformation}>
        <PageHeader
          title={data?.contentfulAboutFinancials?.headerTitle || ""}
          subtitle={
            data?.contentfulAboutFinancials?.headerDescription
              ?.childMarkdownRemark?.rawMarkdownBody || ""
          }
        />
        <div className={styles.yearSelector}>
          <div className={styles.selectedYear}>
            <div>{selectedYear}</div>
            <div className={styles.underline} />
          </div>
          <div className={styles.yearSelectorInputs}>
            <label>
              <input
                className={styles.searchSortMobileRadio}
                type="radio"
                value={data?.contentfulAboutFinancials?.currentYear}
                checked={selectedYear === FinancialYears["CurrentYear"]}
                onChange={(e) =>
                  setSelectedYear(e.target.value as FinancialYears)
                }
              />
              Latest year
            </label>
            <label>
              <input
                className={styles.searchSortMobileRadio}
                type="radio"
                value={data?.contentfulAboutFinancials?.previousYear}
                checked={selectedYear === FinancialYears["PreviousYear"]}
                onChange={(e) =>
                  setSelectedYear(e.target.value as FinancialYears)
                }
              />
              Previous year
            </label>
          </div>
        </div>
        {/* three images here. selecting a year should change the images */}
        <div className={styles.imageSection}>
          {revenueImage && (
            <GatsbyImage
              className={styles.financialImage}
              image={revenueImage}
              alt="revenue image"
              loading="eager"
              objectFit="contain"
              objectPosition="top"
              backgroundColor="#fff"
            />
          )}
          {expensesImage && (
            <GatsbyImage
              className={styles.financialImage}
              image={expensesImage}
              alt="expenses image"
              loading="eager"
              objectFit="contain"
              objectPosition="top"
              backgroundColor="#fff"
            />
          )}
          {assetsImage && (
            <GatsbyImage
              className={styles.financialImage}
              image={assetsImage}
              alt="net assets image"
              loading="eager"
              objectFit="contain"
              objectPosition="top"
              backgroundColor="#fff"
            />
          )}
        </div>

        <div className={styles.additionalInfo}>
          <div className={styles.content}>
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${
                  data?.contentfulAboutFinancials?.accredidation?.image?.fluid
                    ?.src || ""
                })`
              }}
            />
            <div className={styles.text}>
              <Markdown
                className={styles.title}
                content={
                  data?.contentfulAboutFinancials?.accredidation?.title
                    ?.childMarkdownRemark?.rawMarkdownBody || ""
                }
              />
              <Markdown
                className={styles.additionalBody}
                content={
                  data?.contentfulAboutFinancials?.accredidation?.description
                    ?.childMarkdownRemark?.rawMarkdownBody || ""
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default FinancialInformationTemplate

export const query = graphql`
  query FinancialPage {
    contentfulAboutFinancials {
      pageTitle
      headerTitle
      headerDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      currentYear
      previousYear
      currentYearExpenses {
        gatsbyImageData(placeholder: NONE, quality: 100)
      }
      currentYearAssets {
        gatsbyImageData(placeholder: NONE, quality: 100)
      }
      currentYearRevenue {
        gatsbyImageData(placeholder: NONE, quality: 100)
      }
      previousYearExpenses {
        gatsbyImageData(placeholder: NONE, quality: 100)
      }
      previousYearAssets {
        gatsbyImageData(placeholder: NONE, quality: 100)
      }
      previousYearRevenue {
        gatsbyImageData(placeholder: NONE, quality: 100)
      }
      accredidation {
        title {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          fluid {
            src
          }
        }
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
    }
  }
`

import React, { FunctionComponent } from "react"
import styles from "./page-header.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface PageHeaderProps {
  title: string
  subtitle: string
}

export const PageHeader: FunctionComponent<PageHeaderProps> = ({
  subtitle,
  title
}) => (
  <header className={styles.pageHeader}>
    <div className={styles.content}>
      <h1>{title}</h1>
      <Markdown className={styles.subtitle} content={subtitle} />
    </div>
  </header>
)
